define("thesun-front/router", ["exports", "thesun-front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('about');
    this.route('contact');
    this.route('blog', {
      path: 'blog/:category'
    });
    this.route('blogdetail', {
      path: 'blogdetail/:id'
    });
    this.route('service');
    this.route('product', {
      path: 'product/:topic'
    });
    this.route('product-list', {
      path: 'product-list/:name'
    });
    this.route('carrers');
    //this.route('careers-detail');
    this.route('careers-detail', {
      path: 'careers-detail/:id'
    });
    this.route('brand');
    this.route('product-detail', {
      path: 'product-detail/:id'
    });
    this.route('loading');
    this.route('category');
    this.route('partner');
  });
});