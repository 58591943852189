define("thesun-front/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApplicationRoute extends Ember.Route {
    model() {
      this.store.unloadAll("topic");
      return Ember.RSVP.hash({
        topic: this.store.findAll("topic"),
        category: this.store.findAll("category")
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = ApplicationRoute;
});