define("thesun-front/components/banner", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
     <!-- <swiper-container  class="mySwiper" navigation="true">
  
  {{#each @data as |data index|}}
      <swiper-slide> </swiper-slide>
     {{/each}}
    </swiper-container> -->
  
  
  <div class="">
    {{#swiper-container pagination=true autoplay=true loop=true}}
      {{#each @data as |data index|}}
          {{#swiper-slide class='myslide myslide-1'}}
            <img class="img-br" src={{data.getPostImage}} alt="">
          {{/swiper-slide}}
      {{/each}}
    {{/swiper-container}}
  </div>
  
  */
  {
    "id": "Fa17fnTQ",
    "block": "{\"symbols\":[\"data\",\"index\",\"@data\"],\"statements\":[[2,\" \"],[3,\" <swiper-container  class=\\\"mySwiper\\\" navigation=\\\"true\\\">\\n\\n{{#each @data as |data index|}}\\n    <swiper-slide> </swiper-slide>\\n   {{/each}}  </swiper-container> \"],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"pagination\",\"autoplay\",\"loop\"],[true,true,true]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"class\"],[\"myslide myslide-1\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[14,0,\"img-br\"],[15,\"src\",[32,1,[\"getPostImage\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"swiper-slide\",\"-track-array\",\"each\",\"swiper-container\"]}",
    "meta": {
      "moduleName": "thesun-front/components/banner.hbs"
    }
  });
  class BannerComponent extends _component.default {}
  _exports.default = BannerComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BannerComponent);
});