define("thesun-front/routes/careers-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CareersDetailRoute extends Ember.Route {
    model(params) {
      this.store.unloadAll("job");
      return this.store.findRecord("job", params["id"]);
    }
  }
  _exports.default = CareersDetailRoute;
});