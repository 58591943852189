define("thesun-front/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EWz4IduW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-pos\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"assets/photo/loading.gif\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "thesun-front/templates/loading.hbs"
    }
  });
});