define("thesun-front/controllers/blog", ["exports", "fetch", "thesun-front/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  if (_environment.default.environment === 'development') {
    var host = _environment.default.localHost;
  } else if (_environment.default.environment === 'production') {
    var host = _environment.default.remoteHost;
  }
  let BlogController = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class BlogController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "search", _descriptor, this);
      _initializerDefineProperty(this, "blog_active_cat", _descriptor2, this);
    }
    changeCategory(category) {
      var _that = this;
      this.blog_active_cat = category;
      this.store.unloadAll("search-blog");
      if (category === "all") {
        this.search = false;
      } else {
        this.search = true;
        return this.store.queryRecord("search-blog", {
          category: category
        }).then(votcher => {
          let model = this.store.peekAll("search-blog");
          return model;
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.search_param;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "blog_active_cat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.blog_active_cat;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeCategory", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeCategory"), _class.prototype)), _class));
});