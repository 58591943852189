define("thesun-front/routes/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryRoute extends Ember.Route {
    model() {
      this.store.unloadAll("topic");
      return this.store.findAll("topic");
    }
  }
  _exports.default = CategoryRoute;
});