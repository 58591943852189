define("thesun-front/services/-ea-motion", ["exports", "ember-animated/services/motion"], function (_exports, _motion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _motion.default;
    }
  });
});