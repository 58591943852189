define("thesun-front/transforms/mmdate", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MmdateTransform extends _transform.default {
    deserialize(serialized) {
      var my_date = new Date(serialized);
      return my_date.getFullYear() + "-" + String(my_date.getMonth() + 1).padStart(2, '0') + "-" + my_date.getDate();
    }
    serialize(deserialized) {
      return deserialized;
    }
  }
  _exports.default = MmdateTransform;
});