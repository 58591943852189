define("thesun-front/modifiers/validity", ["exports", "ember-validity-modifier/modifiers/validity"], function (_exports, _validity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _validity.default;
    }
  });
});