define("thesun-front/routes/blogdetail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BlogdetailRoute extends Ember.Route {
    model(params) {
      this.store.unloadAll("post");
      return Ember.RSVP.hash({
        category: this.store.findAll("category"),
        model: this.store.findRecord("post", params["id"])
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = BlogdetailRoute;
});