define("thesun-front/routes/home", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeRoute extends Ember.Route.extend(_routeMixin.default) {
    model(params) {
      this.store.unloadAll("post");
      this.store.unloadAll("product");
      this.store.unloadAll("banner");
      this.store.unloadAll("product-category");
      params["per_page"] = 4;
      return Ember.RSVP.hash({
        banner: this.store.findAll("banner"),
        post: this.findPaged("post", params),
        servicePost: this.findPaged("service-post", params),
        product: this.store.findAll("product"),
        category: this.store.findAll("product-category")
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = HomeRoute;
});