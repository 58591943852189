define("thesun-front/components/blog-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="col-lg-4 col-md-6 col-sm-10">
    <LinkTo @route="product-detail" @model={{@data.id}}>
                                            <div class="">
                                                <div class="card blog-post-item-two">
                                                    <div class="blog-post-thumb-two">
                                                        <a ><img src={{@data.getPostImage}} alt=""></a>
                                                        <a  class="tag tag-two">{{@data.price}} Ks </a>
                                                    </div>
                                                    <h5 class=" pd-15">
                                                      <a href="#">{{@data.name}}</a>
                                                    </h5>
  
                                                </div>
                                            </div>
                                            </LinkTo>
  </div>
  
  */
  {
    "id": "si2dHd00",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[10,\"div\"],[14,0,\"col-lg-4 col-md-6 col-sm-10\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"product-detail\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                                          \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n                                              \"],[10,\"div\"],[14,0,\"card blog-post-item-two\"],[12],[2,\"\\n                                                  \"],[10,\"div\"],[14,0,\"blog-post-thumb-two\"],[12],[2,\"\\n                                                      \"],[10,\"a\"],[12],[10,\"img\"],[15,\"src\",[32,1,[\"getPostImage\"]]],[14,\"alt\",\"\"],[12],[13],[13],[2,\"\\n                                                      \"],[10,\"a\"],[14,0,\"tag tag-two\"],[12],[1,[32,1,[\"price\"]]],[2,\" Ks \"],[13],[2,\"\\n                                                  \"],[13],[2,\"\\n                                                  \"],[10,\"h5\"],[14,0,\" pd-15\"],[12],[2,\"\\n                                                    \"],[10,\"a\"],[14,6,\"#\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n                                                  \"],[13],[2,\"\\n\\n                                              \"],[13],[2,\"\\n                                          \"],[13],[2,\"\\n                                          \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "thesun-front/components/blog-item.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});