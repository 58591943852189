define("thesun-front/routes/brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BrandRoute extends Ember.Route {
    model() {
      this.store.unloadAll("banner");
      return Ember.RSVP.hash({
        banner: this.store.findAll("banner")
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = BrandRoute;
});