define("thesun-front/components/swiper-container", ["exports", "ember-cli-swiper/components/swiper-container"], function (_exports, _swiperContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _swiperContainer.default;
    }
  });
});