define("thesun-front/services/modal-dialog", ["exports", "thesun-front/config/environment", "ember-modal-dialog/utils/config-utils"], function (_exports, _environment, _configUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function computedFromConfig(prop) {
    return Ember.computed(function () {
      return _environment.default['ember-modal-dialog'] && _environment.default['ember-modal-dialog'][prop];
    });
  }
  var _default = _exports.default = Ember.Service.extend({
    hasEmberTether: computedFromConfig('hasEmberTether'),
    hasLiquidWormhole: computedFromConfig('hasLiquidWormhole'),
    hasLiquidTether: computedFromConfig('hasLiquidTether'),
    destinationElementId: Ember.computed(function () {
      return (0, _configUtils.getDestinationElementIdFromConfig)(_environment.default);
    })
  });
});