define("thesun-front/routes/product", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ProductRoute = _exports.default = (_dec = Ember.inject.service, (_class = class ProductRoute extends Ember.Route.extend(_routeMixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    model(params) {
      //  return this.store.findAll("post");
      this.store.unloadAll("product-category");
      this.store.unloadAll("banner");
      this.store.unloadAll("search-product");
      this.store.unloadAll("singletopic");
      this.topic = params["topic"];
      params["per_page"] = 12;
      return Ember.RSVP.hash({
        banner: this.store.findAll("banner"),
        //model: this.findPaged("product", params),
        //model: this.store.findAll("product-category"),
        category: this.store.queryRecord("product-category", {
          topic: params["topic"]
        }).then(catlist => {
          let model = this.store.peekAll("product-category");
          return model;
        }),
        product: this.store.queryRecord("search-product", {
          category: "all",
          topic: params["topic"]
        }).then(catlist => {
          let model = this.store.peekAll("search-product");
          return model;
        }),
        singletopic: this.store.queryRecord("singletopic", {
          topic: params["topic"]
        }).then(catlist => {
          let model = this.store.peekAll("singletopic");
          return model;
        })
      });
    }
    setupController(controller, models) {
      controller.set('select_sub_category', null);
      controller.set('topic', this.get('topic'));
      controller.setProperties(models);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});