define("thesun-front/helpers/form-errors", ["exports", "ember-validity-modifier/helpers/form-errors"], function (_exports, _formErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formErrors.default;
    }
  });
});